import { AxiosResponse } from 'axios';
import { withHttpClient } from './http';
import {
    IntermediaryEconomicDataCollection,
    IntermediaryPolicySummaryCollection
} from '@services/models/intermediary-model';
import { PolicyDetail } from '@services/models/policies-model';
import { Product } from '@services/business-lines-service';
import { AllowedLanguages } from '@services/models/language-model';
import { getUserLang } from '@utils/language-helper';

export type PagedRequest = {
    page: number;
    itemsPerPage: number;
};

export type IntermediaryPoliciesFilterRequest = {
    dateFrom?: Date;
    dateTo?: Date;
    policyNumber?: number;
    language: string;
};

export type IntermediaryEconomicDataFilterRequest = {
    dateFrom?: Date;
    dateTo?: Date;
    policyNumber?: number;
    contractId?: number;
    language: string;
};

export type IntermediaryPoliciesRequest = IntermediaryPoliciesFilterRequest & PagedRequest;
export type IntermediaryEconomicDataRequest = IntermediaryEconomicDataFilterRequest & PagedRequest;

export type GetWinnersClubAccessResponse = {
    winnersClubAccess: boolean;
};

export type SendWelcomePackEmailRequest = {
    policyId: string;
    email: string;
};

export type GetIntermediaryWellcomePackBundleRequest = {
    policyId: string;
    language?: string;
};

export type GetAgencyInfoRequest = {
    entityId: string;
    agencyName?: string;
    language?: string;
};

export type GetAgencyInfoResponse = {
    agentId: number;
    iconPath?: string;
    isManualAgency?: boolean;
    allowedLanguages: AllowedLanguages[];
};

export type GetIntermediaryProductsRequest = {
    entityId?: string;
    language?: string;
};

export type GetIntermediaryProductsResponse = {
    products: Product[];
};

export type GetAgencyAgentRequest = {
    entityId: string;
    agencyName: string;
};

export type GetAgencyAgentResponse = {
    agentId: number;
    agentName: string;
};

export type GetIntermediariesAffiliationResponse = {
    statusCode: number;
    value: string[];
};

export type GetIntermediariesLegalResponse = {
    statusCode: number;
    value: string[];
};

const getIntermediaryPolicies = (
    filters?: IntermediaryPoliciesRequest
): Promise<AxiosResponse<IntermediaryPolicySummaryCollection>> =>
    withHttpClient(httpClient =>
        httpClient.post<IntermediaryPolicySummaryCollection>('/intermediaries/policies', filters)
    );

const getIntermediaryPolicy = (request?: {
    numPolicy: number;
    language: string;
}): Promise<AxiosResponse<PolicyDetail>> =>
    withHttpClient(httpClient =>
        httpClient.get<PolicyDetail>(
            `/intermediaries/policies/${request?.numPolicy}/${request?.language}`
        )
    );

const getIntermediaryPoliciesExcelReport = (
    filters?: IntermediaryPoliciesFilterRequest
): Promise<AxiosResponse<Blob>> =>
    withHttpClient(httpClient =>
        httpClient.post<Blob>('/intermediaries/policies/report', filters, { responseType: 'blob' })
    );

const getIntermediaryEconomicData = (
    filters?: IntermediaryEconomicDataRequest
): Promise<AxiosResponse<IntermediaryEconomicDataCollection>> =>
    withHttpClient(httpClient =>
        httpClient.post<IntermediaryEconomicDataCollection>(
            '/intermediaries/policies/economicData',
            filters
        )
    );

const getIntermediaryEconomicDataExcelReport = (
    filters?: IntermediaryEconomicDataFilterRequest
): Promise<AxiosResponse<Blob>> =>
    withHttpClient(httpClient =>
        httpClient.post<Blob>('/intermediaries/policies/economicData/report', filters, {
            responseType: 'blob'
        })
    );

const getWinnersClubAccess = (): Promise<AxiosResponse<GetWinnersClubAccessResponse>> =>
    withHttpClient(httpClient =>
        httpClient.get<GetWinnersClubAccessResponse>('/intermediaries/winnersClub')
    );

const sendIntermediaryWelcomPackEmail = (
    request?: SendWelcomePackEmailRequest
): Promise<AxiosResponse> =>
    withHttpClient(httpClient =>
        httpClient.post(`/intermediaries/wellcomepack/sendEmail/${getUserLang()}`, request)
    );

const getIntermediaryWellcomePackBundle = (
    request?: GetIntermediaryWellcomePackBundleRequest
): Promise<AxiosResponse<Blob>> =>
    withHttpClient(httpClient =>
        httpClient.get<Blob>(
            `intermediaries/policy/${request?.policyId}/wellcomepack/${request?.language}`,
            {
                responseType: 'blob'
            }
        )
    );

const getAgencyInfo = (
    request?: GetAgencyInfoRequest
): Promise<AxiosResponse<GetAgencyInfoResponse>> =>
    withHttpClient(httpClient => httpClient.post('/intermediaries/agency/info', request));

const getIntermediaryProducts = (
    request?: GetIntermediaryProductsRequest
): Promise<AxiosResponse<GetIntermediaryProductsResponse>> =>
    withHttpClient(httpClient =>
        httpClient.get(`/intermediaries/${request?.entityId}/products/${request?.language}`)
    );

const getAgencyAgent = (
    request?: GetAgencyAgentRequest
): Promise<AxiosResponse<GetAgencyAgentResponse>> =>
    withHttpClient(httpClient => httpClient.post('/intermediaries/agency/agent', request));

const getIntermediariesAffiliation = (): Promise<AxiosResponse<
    GetIntermediariesAffiliationResponse
>> =>
    withHttpClient(httpClient =>
        httpClient.get<GetIntermediariesAffiliationResponse>(
            '/intermediaries/intermediariesAffiliation'
        )
    );

const getIntermediariesLegal = (): Promise<AxiosResponse<GetIntermediariesLegalResponse>> =>
    withHttpClient(httpClient =>
        httpClient.get<GetIntermediariesLegalResponse>('/intermediaries/intermediariesLegal')
    );

export {
    getIntermediaryPolicies,
    getIntermediaryPolicy,
    getIntermediaryPoliciesExcelReport,
    getIntermediaryEconomicData,
    getIntermediaryEconomicDataExcelReport,
    getWinnersClubAccess,
    sendIntermediaryWelcomPackEmail,
    getIntermediaryWellcomePackBundle,
    getAgencyInfo,
    getIntermediaryProducts,
    getAgencyAgent,
    getIntermediariesAffiliation,
    getIntermediariesLegal
};
