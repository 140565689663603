import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import es from 'date-fns/locale/es';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { isSpanishLang } from '@utils/language-helper';
import { dateToIsoDate } from '@utils/dates-helper';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';

const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export type CalendarProps = {
    onChange?: (value: Date | null) => void;
    hasError?: boolean;
    currentDayIsMaxDate?: boolean;
} & Omit<ReactDatePickerProps, 'onChange'>;

const Calendar = (props: CalendarProps) => {
    const {
        placeholderText,
        onChange,
        selected,
        hasError,
        disabled,
        showMonthDropdown = false,
        showYearDropdown = true,
        name,
        minDate,
        maxDate,
        currentDayIsMaxDate = true,
        yearDropdownItemNumber,
        startDate,
        endDate,
        autoComplete = 'off'
    } = props;
    const [selectedDate, setSelectedDate] = useState(selected ? dateToIsoDate(selected) : null);
    const startDateUtc = useMemo(() => startDate && dateToIsoDate(startDate), [startDate]);
    const endDateUtc = useMemo(() => endDate && dateToIsoDate(endDate), [endDate]);
    const minDateUtc = useMemo(() => minDate && dateToIsoDate(minDate), [minDate]);
    const maxDateUtc = useMemo(() => maxDate && dateToIsoDate(maxDate), [maxDate]);
    const {
        i18n: { language }
    } = useTranslation();

    const handleOnChange = useCallback(
        (date: Date | null) => {
            const utcDate = date ? dateToIsoDate(date) : null;
            setSelectedDate(utcDate);
            onChange?.(utcDate ?? null);
        },
        [onChange]
    );

    const dateFormat = isSpanishLang(language) ? 'dd/MM/yyyy' : 'MM/dd/yyyy';

    useEffect(() => {
        setSelectedDate(selected ? dateToIsoDate(selected) : null);
    }, [selected]);

    return (
        <DatePicker
            {...props}
            locale={isSpanishLang(language) ? es : language}
            dateFormatCalendar="MMMM yyyy"
            dateFormat={dateFormat}
            yearDropdownItemNumber={yearDropdownItemNumber || 100}
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            dropdownMode="select"
            selected={selectedDate}
            className={classNames({
                'ea-textfield': true,
                'ea-validate-msg--error': hasError
            })}
            dayClassName={date =>
                startDateUtc && endDateUtc && (date <= startDateUtc || date >= endDateUtc)
                    ? 'react-datepicker__day--outside-range'
                    : null
            }
            name={name}
            disabled={disabled}
            onChange={handleOnChange}
            calendarClassName="ea-calendar"
            autoComplete={autoComplete}
            placeholderText={placeholderText || dateFormat?.toLowerCase()}
            customInput={<MaskedInput mask={dateMask} guide={false} />}
            startDate={startDateUtc}
            endDate={endDateUtc}
            minDate={minDateUtc}
            maxDate={currentDayIsMaxDate ? new Date() : maxDateUtc}
            preventOpenOnFocus
            popperModifiers={{
                preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport'
                }
            }}
        />
    );
};

export { Calendar };
