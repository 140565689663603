import React, {
    createContext,
    useContext,
    useMemo,
    Context,
    useState,
    useEffect,
    useCallback
} from 'react';
import { PropsWithChildren } from 'react';
import { routesConfig } from '@routes/routes-config';
import { useLocation, useHistory } from 'react-router-dom';
import { configuration } from '@configuration';
import { useUserContext } from '@context/user/user-context';
import { useAuthContext } from '@context/auth/auth-context';

type AppContextProps = {
    isTravelnetLauncherInHeader: boolean;
    saleInProgress: boolean;
    productIconPath?: string;
};

type AppContextActionsProps = {
    getHomeUrl: () => { isExternal: boolean; url: string };
    navigateToHome: (options?: { openInNewTab?: boolean; replace?: boolean }) => void;
    setProductIconPath: (path?: string) => void;
};

const AppContextActions = createContext<Partial<AppContextActionsProps>>({}) as Context<
    AppContextActionsProps
>;
const AppContext = createContext<Partial<AppContextProps>>({}) as Context<AppContextProps>;

const AppProvider = ({ children }: PropsWithChildren<{}>) => {
    const { pathname } = useLocation();
    const history = useHistory();
    const { user } = useAuthContext();
    const { agentData } = useUserContext();
    const [productIconPath, setProductIconPath] = useState<string>();

    const isTravelnetLauncherInHeader = useMemo(() => pathname === routesConfig.travelnet.home, [
        pathname
    ]);

    const isSaleRoute = useMemo(
        () => pathname.includes('funnel') && pathname !== routesConfig.funnel.home,
        [pathname]
    );

    const saleInProgress = useMemo(
        () => isSaleRoute && pathname !== routesConfig.funnel.paymentSuccess,
        [isSaleRoute, pathname]
    );

    useEffect(() => {
        if (!isSaleRoute) {
            setProductIconPath(undefined);
        }
    }, [isSaleRoute]);

    const getHomeUrl = useCallback(() => {
        let url: string;
        let isExternal = false;
        if (agentData?.entityId) {
            url = `${routesConfig.agencies.landing}/${agentData.entityId}${
                agentData.agencyName ? `/${encodeURIComponent(agentData.agencyName)}` : ''
            }`;
        } else if (user?.role === 'intermediary') {
            url = routesConfig.travelnet.home;
        } else if (configuration.debug) {
            url = routesConfig.funnel.home;
        } else {
            url = configuration.server.CMS;
            isExternal = true;
        }
        return { isExternal, url };
    }, [agentData?.entityId, user?.role]);

    const navigateToHome = useCallback(
        (options?: { openInNewTab?: boolean; replace?: boolean }) => {
            const { openInNewTab = false, replace = false } = options ?? {};

            const homeUrl = getHomeUrl();

            if (openInNewTab) {
                window.open(homeUrl.url, '_blank', 'noopener noreferrer');
                return;
            }

            if (homeUrl.isExternal) {
                location.href = homeUrl.url;
                return;
            }
            if (replace) {
                history.replace(homeUrl.url);
            } else {
                history.push(homeUrl.url);
            }
        },
        [getHomeUrl]
    );

    const value = useMemo(
        () => ({
            isTravelnetLauncherInHeader,
            saleInProgress,
            productIconPath
        }),
        [isTravelnetLauncherInHeader, saleInProgress, productIconPath]
    );

    const actionsValue = useMemo(
        () => ({
            getHomeUrl,
            navigateToHome,
            setProductIconPath
        }),
        [getHomeUrl, setProductIconPath]
    );

    return (
        <AppContextActions.Provider value={actionsValue}>
            <AppContext.Provider value={value}>{children}</AppContext.Provider>
        </AppContextActions.Provider>
    );
};

const useAppContext = () => useContext(AppContext);
const useAppContextActions = () => useContext(AppContextActions);

export { AppProvider, useAppContext, useAppContextActions };
